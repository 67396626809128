<template>
  <sec-operations />
</template>

<script>
import SecOperations from "../../components/solutions/security-solutions/SecOperations.vue";
export default {
  components: { SecOperations },
  name: "security-operations-section",
};
</script>
